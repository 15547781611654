<div nz-row nzGutter="8" nzJustify="center" *ngIf="displayLoader">
	<div nz-col nzSpan="24">
		<nz-spin nzTip="Création et envoie des fichiers en cours">
		</nz-spin>
	</div>
</div>
<form *ngIf="form && displayForm" [formGroup]="form.validationForm" nz-form nzLayout="vertical">
	<div nz-row nzGutter="15">
		<div nz-col nzSpan="24">
			<nz-form-item *ngIf="!isCreateMode && status">
				<nz-form-control>
					<div [ngClass]="{   'validated': status.id === declarationStatusEnum.VALIDATED,
                                'denied': status.id === declarationStatusEnum.DENIED,
                                'waiting': status.id === declarationStatusEnum.WAITING_VALIDATION
                            }"
					     class="item-status">
						{{status.name}}
						<nz-tag [nzColor]="'#40a9ff'" *ngIf="form.validationForm.get('paid').value === true">Déjà payé</nz-tag>
					</div>
				</nz-form-control>
			</nz-form-item>
		</div>
	</div>
	<div nz-row nzGutter="15">
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Catégorie</nz-form-label>
				<nz-form-control [nzErrorTip]="categoryErrorTips">
					<nz-select (ngModelChange)="categoryChangedHandler()" [formControlName]="'category'"
					           [nzDisabled]="locked">
						<nz-option [nzLabel]="'Frais structurel'" [nzValue]="1"></nz-option>
						<nz-option [nzLabel]="'Frais de projet'" [nzValue]="2"></nz-option>
					</nz-select>
					<ng-template #categoryErrorTips>
						<div *ngIf="form.validationForm.get('category').dirty && form.validationForm.get('category').errors">
							Veuillez saisir la catégorie de dépense
						</div>
					</ng-template>
				</nz-form-control>
			</nz-form-item>
		</div>
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Type</nz-form-label>
				<nz-form-control [nzErrorTip]="typeErrorTips">
					<nz-select (ngModelChange)="typeChangeHandler()" [formControlName]="'type'" [nzDisabled]="locked">
						<ng-container *ngFor="let type of expenseReportTypes">
							<nz-option [nzHide]="type.name === 'Frais de panier repas' && form.validationForm.get('category').value === 1"
							           [nzLabel]="type.name"
							           [nzValue]="type.id"></nz-option>
						</ng-container>
					</nz-select>
					<ng-template #typeErrorTips>
						Veuillez saisir un type de dépense
					</ng-template>
				</nz-form-control>
			</nz-form-item>
		</div>
	</div>
	<ng-container *ngIf="this.form.validationForm.get('category').value === 2">
		<div nz-row nzGutter="15">
			<div nz-col nzSpan="12">
				<nz-form-item>
					<nz-form-label nzRequired>Projet</nz-form-label>
					<nz-form-control [nzErrorTip]="projectErrorTips">
						<nz-select (ngModelChange)="projectChangedHandler($event)" *ngIf="projects"
						           [formControlName]="'projectId'" [nzDisabled]="locked">
							<nz-option *ngFor="let project of projects" [nzLabel]="project.name"
							           [nzValue]="project.id"></nz-option>
						</nz-select>
						<ng-template #projectErrorTips>
							Veuillez saisir un projet
						</ng-template>
					</nz-form-control>
				</nz-form-item>
			</div>
			<div nz-col nzSpan="12">
				<nz-form-item *ngIf="lots">
					<nz-form-label nzRequired>Lot</nz-form-label>
					<nz-form-control nzErrorTip="Veuillez saisir un lot de projet">
						<nz-select [formControlName]="'lotId'" [nzDisabled]="locked">
							<nz-option *ngFor="let lot of lots" [nzLabel]="lot.name" [nzValue]="lot.id"></nz-option>
							<nz-option [nzLabel]="'Tous le projet'" [nzValue]="0"></nz-option>
						</nz-select>
					</nz-form-control>
				</nz-form-item>
			</div>
		</div>
	</ng-container>
	<div *ngIf="form.validationForm.get('type').value === 1">
		<ng-container *ngIf="activeCars && activeCars.length > 0;">
			<div nz-row nzGutter="15" *ngIf="this.form.validationForm.get('category').value === 2">
				<div nz-col nzSpan="12">
					<nz-form-item>
						<nz-form-label nzRequired>Site client</nz-form-label>
						<nz-form-control nzErrorTip="Veuillez sélectionner un site client">
							<nz-select formControlName="siteId" [nzDisabled]="locked"
							           (ngModelChange)="siteChangeHandler($event)">
								<nz-option *ngFor="let site of projectCustomerSites" [nzLabel]="site.name" [nzValue]="site.id"></nz-option>
							</nz-select>
						</nz-form-control>
					</nz-form-item>
				</div>
			</div>
			<div nz-row nzGutter="15">
				<div nz-col nzSpan="12">
					<nz-form-item>
						<nz-form-label nzRequired>Voiture</nz-form-label>
						<nz-form-control nzErrorTip="Veuillez saisir une voiture">
							<nz-select [formControlName]="'carId'" [nzDisabled]="locked">
								<nz-option *ngFor="let car of activeCars" [nzLabel]="car.model" [nzValue]="car.id"></nz-option>
							</nz-select>
						</nz-form-control>
					</nz-form-item>
				</div>
				<div nz-col nzSpan="12">
					<nz-form-item>
						<nz-form-label nzRequired>
						<span nz-tooltip nzTooltipTitle="Cliquer sur l'icone ? pour connaitre les méthodes de calculs des frais kilométriques"
						      style="padding-right: 8px;">
							Distance en KM</span>
							<fa-icon (click)="carTipsModalVisible = true;" [icon]="['far', 'question-circle']"
							         class="hover-pointer"></fa-icon>
						</nz-form-label>
						<nz-form-control nzErrorTip="Veuillez saisir la distance en km">
							<input [formControlName]="'mileage'" nz-input/>
						</nz-form-control>
					</nz-form-item>
				</div>
			</div>
			<div nz-row nzGutter="15">
				<div nz-col nzSpan="12">
					<div *ngIf="isCreateMode === false" nz-col nzSpan="12" [ngClass]="{'hidden': form.validationForm.get('type').value === 1}">
						<nz-form-item>
							<nz-form-label>Dépense</nz-form-label>
							<nz-form-control>
								<input [formControlName]="'amount'" nz-input/>
							</nz-form-control>
						</nz-form-item>
					</div>
				</div>
			</div>
		</ng-container>
		<div *ngIf="activeCars && activeCars.length == 0;" nz-col nzSpan="24">
			<nz-alert
					[nzDescription]="noCarWarning"
					nzMessage="Aucune voiture associé à votre profile"
					nzType="warning"
			></nz-alert>
			<ng-template #noCarWarning>
				<p>Vous devez posséder au moins une voiture pour pouvoir faire une déclaration de frais de déplacement personnel en voiture.</p>
				<p>Vous pouvez ajouter une voiture depuis votre profile, accéder à votre profile en cliquant sur votre nom d'utilisateur en haut à droite.</p>
			</ng-template>
		</div>
	</div>
	<div *ngIf="form.validationForm.get('type').value !== 1" nz-row nzGutter="15">
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Dépense</nz-form-label>
				<nz-form-control [nzErrorTip]="amountErrorTips">
					<input [formControlName]="'amount'" nz-input/>
					<ng-template #amountErrorTips>
						Veuillez saisir le cout de dépense
					</ng-template>
				</nz-form-control>
			</nz-form-item>
		</div>
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label>TVA récupérable</nz-form-label>
				<nz-form-control [nzErrorTip]="vatRecoverableErrorTips">
					<input [formControlName]="'recoverableVat'" nz-input/>
					<ng-template #vatRecoverableErrorTips>
						Veuillez saisir le montant de la tva récupérable
					</ng-template>
				</nz-form-control>
			</nz-form-item>
		</div>
	</div>
	<div *ngIf="form.validationForm.get('type').value !== 1" nz-row nzGutter="15">
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Refacturable</nz-form-label>
				<nz-form-control>
					<nz-switch [formControlName]="'chargeable'"></nz-switch>
				</nz-form-control>
			</nz-form-item>
		</div>
	</div>
	<div *ngIf="declarationMode === declarationModeEnum.MANAGER">
		<div nz-row nzGutter="15">
			<div nz-col nzSpan="12">
				<nz-form-item>
					<nz-form-label nzRequired>Déjà payé</nz-form-label>
					<nz-form-control>
						<nz-switch [formControlName]="'paid'"></nz-switch>
					</nz-form-control>
				</nz-form-item>
			</div>
		</div>
	</div>
	<nz-form-item>
		<nz-form-label>Description</nz-form-label>
		<nz-form-control>
			<textarea [formControlName]="'description'" nz-input></textarea>
		</nz-form-control>
	</nz-form-item>
	<div nz-row nzGutter="15" *ngIf="isCreateMode === false">
		<div nz-col nzSpan="24">
			<nz-card class="expense-report-create-card" [nzTitle]="titleTemplate"
			         [nzExtra]="extraTemplate">
				<ng-template #titleTemplate>
					<span nz-tooltip nzTooltipTitle="Optionnel, format PDF ou PNG/JPEG">
						Documents justificatifs (optionnel, format PDF ou PNG/JPEG)
					</span>
				</ng-template>
				<ng-template #extraTemplate>
					<form [formGroup]="fileUploadForm.validationForm" nz-form nzLayout="horizontal"
					      *ngIf="status.id === declarationStatusEnum.WAITING_VALIDATION">
						<div nz-row nzGutter="8">
							<div nz-col nzSpan="8">
								<button (click)="openFileDiagUpdate()" [nzTooltipTitle]="uploadToolTip" nz-button nz-tooltip nzType="default"
								        style="cursor: pointer;">
									<fa-icon [icon]="['far', 'upload']" class="gaia-highlight-primary" style="padding-right: 10px"></fa-icon>
									<span>Déposer</span>
									<input (change)="uploadFileSelectForUpdate($event)" accept="application/pdf, application/pnb, application/jpeg"
									       id="fileUploadInputExpenseReportUpdate" name="file" nz-button
									       type="file"/>
									<ng-template #uploadToolTip>
										<span>Déposer un document</span>
									</ng-template>
								</button>
							</div>
						</div>
					</form>
				</ng-template>
				<nz-list *ngIf="expenseReport.files" nzBordered="false" nzSize="small">
					<nz-list-item *ngFor="let file of expenseReport.files" style="padding: 5px 0 5px 0;">
						<div nz-row nzJustify="start" style="width: 100%;">
							<div nz-col nz-tooltip nzTooltipTitle="{{file.fileName}}" nzSpan="14">
								<span>{{ file.name }}</span>
							</div>
							<div>
								<a href="{{ file.url }}" nz-tooltip nzTooltipTitle="Voir"
								   style="color: rgba(0, 0, 0, 0.65);" target="_blank">
									<fa-icon [icon]="['far', 'eye']" class="gaia-highlight-primary" style="padding-right: 10px;cursor: pointer;"></fa-icon>
								</a>

								<fa-icon (click)="downloadPdf(file)" [icon]="['far', 'download']"
								         class="gaia-highlight-primary"
								         nz-tooltip nzTooltipTitle="Télécharger" style="padding-right: 10px;cursor: pointer;"></fa-icon>

								<fa-icon (click)="deleteFile(file)" [icon]="['far', 'trash']"
								         *ngIf="status.id === declarationStatusEnum.WAITING_VALIDATION"
								         style="cursor: pointer;"
								         class="gaia-highlight-primary" nz-tooltip nzTooltipTitle="Supprimer"></fa-icon>
							</div>
						</div>
					</nz-list-item>
				</nz-list>
				<div nz-row nzJustify="center">
					<span *ngIf="expenseReport.files.length === 0" style="text-align: center;">Aucun document</span>
				</div>
			</nz-card>
		</div>
	</div>
	<div nz-row nzGutter="15" *ngIf="isCreateMode">
		<div nz-col nzSpan="24">
			<nz-card class="expense-report-create-card" [nzTitle]="titleTemplate"
			         [nzExtra]="extraTemplate">
				<ng-template #titleTemplate>
					<span nz-tooltip nzTooltipTitle="Optionnel, format PDF ou PNG/JPEG">
						Documents justificatifs (optionnel, format PDF ou PNG/JPEG)
					</span>
				</ng-template>
				<ng-template #extraTemplate>
					<form [formGroup]="fileUploadForm.validationForm" nz-form nzLayout="horizontal">
						<div nz-row nzGutter="8">
							<div nz-col nzSpan="8">
								<button (click)="openFileDiagCreate()" [nzTooltipTitle]="uploadToolTip" nz-button nz-tooltip nzType="default"
								        style="cursor: pointer;">
									<fa-icon [icon]="['far', 'upload']" class="gaia-highlight-primary" style="padding-right: 10px"></fa-icon>
									<span>Déposer</span>
									<input (change)="uploadFileSelectForCreate($event)" accept="application/pdf, application/pnb, application/jpeg"
									       id="fileUploadInputExpenseReportCreate" name="file" nz-button
									       type="file"/>
									<ng-template #uploadToolTip>
										<span>Déposer un document</span>
									</ng-template>
								</button>
							</div>
						</div>
					</form>
				</ng-template>
				<nz-list *ngIf="filesToUpload" nzBordered="false" nzSize="small">
					<nz-list-item *ngFor="let item of filesToUpload" style="padding: 5px 0 5px 0;">
						<div nz-row nzJustify="start" style="width: 100%;">
							<div nz-col nzSpan="14">
								<span>{{ item.file.name }}</span>
							</div>
							<div nz-col nzSpan="10" style="text-align: left;">
								<fa-icon (click)="deleteFileToUpload(item.file.name)" [icon]="['far', 'trash']" style="cursor: pointer;"
								         class="gaia-highlight-primary" nz-tooltip nzTooltipTitle="Supprimer"></fa-icon>
							</div>
						</div>
					</nz-list-item>
				</nz-list>
				<div nz-row nzJustify="center">
					<span *ngIf="filesToUpload.length === 0" style="text-align: center;">Aucun document</span>
				</div>
			</nz-card>
		</div>
	</div>
	<div nz-row nzGutter="15" nzJustify="end">
		<div class="action-buttons" nz-col nzSpan="24">
			<ng-container *ngIf="!locked">
				<ng-container *ngIf="!expenseReport.status">
					<button (click)="closeModalHandler();" *ngIf="!expenseReport.status" [nzType]="'default'"
					        nz-button>
						Annuler
					</button>
					<button (click)="sendDeclaration();" [nzType]="'primary'"
					        nz-button style="margin-left: 15px;">
						Confirmer
					</button>
				</ng-container>
				<ng-container
						*ngIf="expenseReport.status && expenseReport.status.id === declarationStatusEnum.WAITING_VALIDATION">
					<button (click)="sendDeclaration();" [nzType]="'primary'"
					        nz-button style="margin-left: 15px;">
						Mettre à jour
					</button>
				</ng-container>
			</ng-container>
		</div>
	</div>
</form>

<nz-modal (nzOnCancel)="carTipsModalVisible = false" (nzOnOk)="carTipsModalVisible = false;"
          [(nzVisible)]="carTipsModalVisible"
          [nzCancelText]="null"
          nzTitle="Barème de calcul des frais kilométriques">
	<ng-container *nzModalContent>
		<h4>Prix de revient kilométriques applicables aux autos.
			<br>
			En euros par kilomètre, Frais de garage exclus. (d = distance parcourue)
			<br><br>
			<nz-alert [nzDescription]="howToCalculateMyScaleTpl" nzMessage="Quel est mon barème ?"
			          nzType="info">
			</nz-alert>
			<ng-template #howToCalculateMyScaleTpl>
				Votre barème est déterminée par le total des distances parcourues au cours de l'année.
				<br>
				Si au cours de l'année, vous dépasser le barème des 5000km ou 20000km,
				le calcul des frais sera ré-ajuster automatiquement
				sur votre prochaine déclaration des frais kilométriques sous réserve de validation par votre manageur.
			</ng-template>
		</h4>
		<nz-table nzShowPagination="false" nzSize="small" nzTemplateMode>
			<thead>
			<tr>
				<th>CV fiscaux</th>
				<th>d <= 5000 km</th>
				<th>de 5001 à 20000 km</th>
				<th>d >= 20001 km</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>3</td>
				<td>0,529 x km</td>
				<td>0,316 x km</td>
				<td>0,370 x km</td>
			</tr>
			<tr>
				<td>4</td>
				<td>0,606 x km</td>
				<td>0,340 x km</td>
				<td>0,407 x km</td>
			</tr>
			<tr>
				<td>5</td>
				<td>0,636 x km</td>
				<td>0,357 x km</td>
				<td>0,427 x km</td>
			</tr>
			<tr>
				<td>6</td>
				<td>0,665 x km</td>
				<td>0,374 x km</td>
				<td>0,447 x km</td>
			</tr>
			<tr>
				<td>7</td>
				<td>0,697 x km</td>
				<td>0,394 x km</td>
				<td>0,470 x km</td>
			</tr>
			</tbody>
		</nz-table>
	</ng-container>
</nz-modal>
